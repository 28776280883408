#root {
    --mm-primary: #232f34;
    --mm-secondary: #1eb8c1;
    --mm-tertiary: #d3dee2;
    --mm-text: #4a6572;
    --mm-background: #efefef;
    --menu-height: 4rem;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
    margin: 0;
    outline: 0;
    border: none !important;
}

#root,
#root > div,
html,
body {
    height: 100%;
    background-color: var(--mm-background);
}

.mm_mainTitle {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.p-datatable-thead {
    th {
        background-color: transparent !important;
        padding: 0 1rem 0 0 !important;
    }
}

.p-datatable-header {
    background-color: transparent !important;
    margin: 0 0 1rem 0 !important;
    padding: 0 !important;
}

.p-datatable-tbody {
    tr {
        overflow: hidden;
        background-color: white !important;
        border: none;
        border-bottom: 0.5rem solid var(--mm-background) !important;
        border-top: 0.5rem solid var(--mm-background) !important;

        @media (max-width: 960px) {
            border-bottom: 1rem solid var(--mm-background) !important;
            border-top: 1rem solid var(--mm-background) !important;
        }

        &.p-datatable-emptymessage {
            background-color: white !important;
        }
    }
}

.p-dialog {
    max-width: 95vw;
}

.p-selectbutton {
    min-width: fit-content;
    border-radius: 0 !important;
    border: none !important;
    box-shadow: none !important;

    > div {
        width: 4rem;
        padding: 0.5rem 0 !important;
        border-radius: 0 !important;
        border: 1px solid rgba(0, 0, 0, 0.1) !important;
        box-shadow: none !important;
        margin-right: 0.5rem !important;
    }
}

.p-selectbutton .p-highlight {
    background-color: #232f34 !important;
}

.p-column-title {
    opacity: 0.8 !important;

    @media (max-width: 960px) {
        opacity: 0.5 !important;
    }
}

.p-inputtextarea {
    max-width: 100%;
}

.p-dropdown,
.p-inputtext {
    border-radius: 0 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
