.page {
    background-color: white;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.content-wrapper {
    padding: 2rem;
    width: 100%;
    position: relative;
    height: calc(100vh - var(--menu-height)) !important;
    overflow-y: auto;

    @media (max-width: 960px) {
        padding: 0.5rem;
    }
}
