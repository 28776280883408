.mm_menu {
    background-color: var(--mm-primary) !important;
    height: var(--menu-height) !important;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 20;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);

    a {
        color: rgba(255, 255, 255, 0.75);
        text-decoration: none;
        padding: 0.65rem 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.65rem;

        &:hover {
            background-color: rgba(255, 255, 255, 0.15) !important;
        }

        &.selectedMenuItem {
            background-color: var(--mm-text) !important;
        }
    }

    .mm_more {
        padding: 0.65rem 1rem;
        color: rgba(255, 255, 255, 0.75);
        background-color: transparent !important;
        margin-right: 0.65rem;

        &:hover {
            background-color: rgba(255, 255, 255, 0.15) !important;
        }
    }

    .mm_extraMenu {
        position: absolute;
        background-color: var(--mm-primary) !important;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100vw;
        right: 0;
        top: var(--menu-height);
        z-index: 10;
        padding: 1rem;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);

        a {
            margin: 0.65rem 0 0;
            justify-content: flex-end;
            padding: 0.65rem 2rem;

            &:first-child {
                margin: 0;
            }
        }
    }
}
