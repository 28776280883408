@import 'primeflex/primeflex.scss';

.field-checkbox {
    @include styleclass('capitalize');
    margin: 0 0 0.2rem 0 !important;

    > label {
        margin: 0 !important;
    }
}
