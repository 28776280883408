.video {
    flex-grow: 1;
}

.qrReader {
    width: 100%;
    height: 100%;
    max-width: 30rem;
}

.grid {
    display: grid;
    grid-template-columns: 1fr min(75vw, 23rem * 0.75) 1fr;
    grid-template-rows: 1fr min(75vw, 23rem * 0.75) 1fr;
    grid-template-areas:
        'top top top'
        'left center right'
        'bot bot bot';
}

.side {
    background-color: rgba(0, 0, 0, 0.3);
}

.top {
    grid-area: top;
}

.bottom {
    grid-area: bot;
}

.left {
    grid-area: left;
}

.right {
    grid-area: right;
}

.center {
    grid-area: center;
    display: grid;
    grid-template-columns: 1fr 40% 1fr;
    grid-template-rows: 1fr 40% 1fr;
    grid-template-areas:
        'topLeft none topRight'
        'none1 none none2'
        'bottomLeft none bottomRight';
}

.corner {
    border-style: solid !important;
    border-color: #1eb8c1 !important;
    border-top-width: 0 !important;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
    border-bottom-width: 0 !important;
}

.topLeftCorner {
    grid-area: topLeft;
    border-top-width: 5px !important;
    border-left-width: 5px !important;
}

.topRightCorner {
    grid-area: topRight;
    border-top-width: 5px !important;
    border-right-width: 5px !important;
}

.bottomLeftCorner {
    grid-area: bottomLeft;
    border-left-width: 5px !important;
    border-bottom-width: 5px !important;
}

.bottomRightCorner {
    grid-area: bottomRight;
    border-right-width: 5px !important;
    border-bottom-width: 5px !important;
}
