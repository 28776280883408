.disabled {
    pointer-events: none !important;
    opacity: 0.6 !important;
}

.loading {
    pointer-events: none !important;
}

.mm_button_full {
    color: white !important;
    cursor: pointer !important;
    width: 100% !important;
    text-transform: uppercase !important;
    background: #232f34 !important;
    border: none !important;
    border-radius: 0 !important;
    padding: 0 0.5rem !important;
    height: 2.8rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    opacity: 0.9;

    &:hover {
        opacity: 1;
    }

    > p {
        font-size: 0.9rem !important;
        font-weight: 600 !important;
    }
}

.mm_button_empty {
    @extend .mm_button_full;
    background: transparent !important;
    background: var(--mm-text) !important;
    border: solid 3px var(--mm-text) !important;
}

.mm_button_empty_inverted {
    @extend .mm_button_full;
    background: transparent !important;
    border: solid 3px white !important;
}

.mm_button_text {
    @extend .mm_button_full;
    color: var(--mm-text) !important;
    background: transparent !important;
}
